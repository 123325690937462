import './AddButtons.css'
import StripeButton from '../../StripeButton/StripeButton';
import dataBase from '../../../config';
import { ref as AddOfferRef, set, get, child, increment } from "firebase/database";
import { useHistory } from 'react-router-dom';
import { useAtom } from 'jotai';
import { useLoggedUser, useCityFilter, useDetailedOffer, useStatuteAcceptation, useChoosedOption, useShowPopUpModal, useModalVersion, useModalInfo, useStepper, useApplyWithSJProfile } from '../../../data/GlobalStates';

      const ShowAddButtons = ({setCountryFilter, setCountryFilterCode, setTitle, setExperience, setSelectedRole, setSelectedSpeciality, setCountry, setDescription, setMinSalary, setMaxSalary, setAwaitOffer, awaitOffer, setRefresh, refresh}) => {

        const history = useHistory();
        const db = dataBase; // zapis
        const dbRef = AddOfferRef(dataBase);// DO ODCZYTU

        const date = new Date();
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDay();    

        //wymagane zmiany:
        //dodanie info do oferty premium paid=== true kiedy logged user ma oferty premium


        //stany globalne:
        const [ LoggedUser, setLoggedUser ] = useAtom(useLoggedUser);
        const [ CityFilter, setCityFilter] = useAtom(useCityFilter);
        const [ DetailedOffer, setDetailedOffer] = useAtom(useDetailedOffer);
        const [ StatuteAcceptation, setStatuteAcceptation] = useAtom(useStatuteAcceptation);
        const [ ChoosedOption, setChoosedOption] = useAtom(useChoosedOption);
        const [ ShowPopUpModal, setShowPopUpModal] = useAtom(useShowPopUpModal);
        const [ ModalVersion, setModalVersion] = useAtom(useModalVersion);
        const [ ModalInfo, setModalInfo] = useAtom(useModalInfo);
        const [ Stepper, setStepper] = useAtom(useStepper);
        const [ ApplyWithSJProfile, setApplyWithSJProfile] = useAtom(useApplyWithSJProfile);

        //const price1 = process.env.REACT_APP_TESTPROD1 // premium testowe
        //const price2 = process.env.REACT_APP_TESTPROD2 // basic+ testowe
        const prodPriceBasicPlus = process.env.REACT_APP_PRODPRICEBASICPLUS // basic+ prod
        const prodPricePremium = process.env.REACT_APP_PRODPRICEPREMIUM // premium prod 59 + vat
        const prodPriceTest = process.env.REACT_APP_PRODPRICELOWTEST // test prod
        const prodPricePromo = process.env.REACT_APP_PRODPRICEPREMIUMPROMOTION // cena promocyjna 23.37
        const newPremiumPrice = process.env.REACT_APP_PRODPRICENEWPREMIUM // nowa cena 193,98 brutto


        const addFreeOffer = () => {
          if(awaitOffer.role === 'Wybierz' || awaitOffer.role === '' || awaitOffer.role === undefined) {
            setShowPopUpModal(true)
            setModalVersion(2)
            window.scrollTo(0,0)
            setModalInfo({title:'Uzupełnij Dane:', message:'- wybierz zawód'})
          } else if ((awaitOffer.speciality === 'Wybierz' || awaitOffer.speciality ==='' || awaitOffer.speciality === undefined) && (awaitOffer.role === 'Monter' || awaitOffer.role === 'Spawacz' || awaitOffer.role === 'CNC')) {
            setShowPopUpModal(true)
            setModalVersion(2)
            window.scrollTo(0,0)
            setModalInfo({title:'Uzupełnij Dane:', message:'- wybierz specjalność'})
          } else if ((awaitOffer.experience === 'Wybierz' || awaitOffer.experience  === '' || awaitOffer.experience  === undefined) && (awaitOffer.role === 'Monter' || awaitOffer.role === 'Spawacz' || awaitOffer.role === 'CNC' || awaitOffer.role === 'Ślusarz' || awaitOffer.role === 'Szlifierz' || awaitOffer.role === 'Kowal' || awaitOffer.role === 'Malarz' || awaitOffer.role === 'Brygadzista') && (awaitOffer.speciality === 'Rurociągi' || awaitOffer.speciality === 'Konstrukcje' || awaitOffer.speciality === 'Wentylacje' || awaitOffer.speciality === 'Ermeto'
          || awaitOffer.speciality === '111' || awaitOffer.speciality === '114' || awaitOffer.speciality === '121' || awaitOffer.speciality === '131' || awaitOffer.speciality === '141' || awaitOffer.speciality === '135' || awaitOffer.speciality === '136' || awaitOffer.speciality === '137' || awaitOffer.speciality === '311'
          || awaitOffer.speciality === 'Prasa' || awaitOffer.speciality === 'Laser' || awaitOffer.speciality === 'Frezarka' || awaitOffer.speciality === 'Tokarka')) {
            setShowPopUpModal(true)
            setModalVersion(2)
            window.scrollTo(0,0)
            setModalInfo({title:'Uzupełnij Dane:', message:'- wybierz poziom doświadczenia'})
          } else if (!awaitOffer.title && (awaitOffer.experience === 'Początkujący' || awaitOffer.experience === 'Śr.-zaawansowany' || awaitOffer.experience === 'Zaawansowany' || awaitOffer.experience === 'Expert') && (awaitOffer.role === 'Monter' || awaitOffer.role === 'Spawacz' || awaitOffer.roletedRole === 'CNC' || awaitOffer.role === 'Ślusarz' || awaitOffer.role === 'Szlifierz' || awaitOffer.role === 'Kowal' || awaitOffer.role === 'Malarz' || awaitOffer.role === 'Brygadzista') && (awaitOffer.speciality === 'Rurociągi' || awaitOffer.speciality  === 'Konstrukcje' || awaitOffer.speciality  === 'Wentylacje' || awaitOffer.speciality  === 'Ermeto'
          || awaitOffer.speciality  === '111' || awaitOffer.speciality  === '114' || awaitOffer.speciality  === '121' || awaitOffer.speciality  === '131' || awaitOffer.speciality  === '141' || awaitOffer.speciality  === '135' || awaitOffer.speciality  === '136' || awaitOffer.speciality  === '137' || awaitOffer.speciality  === '311'
          || awaitOffer.speciality  === 'Prasa' || awaitOffer.speciality  === 'Laser' || awaitOffer.speciality  === 'Frezarka' || awaitOffer.speciality  === 'Tokarka')) {
            setShowPopUpModal(true)
            setModalVersion(2)
            window.scrollTo(0,0)
            setModalInfo({title:'Uzupełnij Dane:', message:'- Uzupełnij Tytuł ogłoszenia'})
          } else if (!awaitOffer.description && awaitOffer.title && (awaitOffer.experience === 'Początkujący' || awaitOffer.experience === 'Średnio-zaawansowany' || awaitOffer.experience === 'Zaawansowany' || awaitOffer.experience === 'Expert') && (awaitOffer.role === 'Monter' || awaitOffer.role === 'Spawacz' || awaitOffer.role === 'CNC' || awaitOffer.role === 'Ślusarz' || awaitOffer.role === 'Szlifierz' || awaitOffer.role === 'Kowal' || awaitOffer.role === 'Malarz' || awaitOffer.role === 'Brygadzista') && (awaitOffer.speciality === 'Rurociągi' || awaitOffer.speciality  === 'Konstrukcje' || awaitOffer.speciality  === 'Wentylacje' || awaitOffer.speciality  === 'Ermeto'
          || awaitOffer.speciality  === '111' || awaitOffer.speciality  === '114' || awaitOffer.speciality  === '121' || awaitOffer.speciality  === '131' || awaitOffer.speciality  === '141' || awaitOffer.speciality  === '135' || awaitOffer.speciality  === '136' || awaitOffer.speciality  === '137' || awaitOffer.speciality  === '311'
          || awaitOffer.speciality  === 'Prasa' || awaitOffer.speciality  === 'Laser' || awaitOffer.speciality  === 'Frezarka' || awaitOffer.speciality  === 'Tokarka')) {
            setShowPopUpModal(true)
            setModalVersion(2)
            window.scrollTo(0,0)
            setModalInfo({title:'Uzupełnij Dane:', message:'- Uzupełnij Treść Ogłoszenia'})
          } else if ((awaitOffer.country === 'Wybierz' || awaitOffer.country === '' || awaitOffer.country === undefined) && awaitOffer.description && awaitOffer.title && (awaitOffer.experience === 'Początkujący' || awaitOffer.experience === 'Średnio-zaawansowany' || awaitOffer.experience === 'Zaawansowany' || awaitOffer.experience === 'Expert') && (awaitOffer.role === 'Monter' || awaitOffer.role  === 'Spawacz' || awaitOffer.role  === 'CNC' || awaitOffer.role  === 'Ślusarz' || awaitOffer.role  === 'Szlifierz' || awaitOffer.role  === 'Kowal' || awaitOffer.role  === 'Malarz' || awaitOffer.role  === 'Brygadzista')  &&( awaitOffer.speciality === 'Rurociągi' || awaitOffer.speciality  === 'Konstrukcje' || awaitOffer.speciality  === 'Wentylacje' || awaitOffer.speciality  === 'Ermeto'
          || awaitOffer.speciality  === '111' || awaitOffer.speciality  === '114' || awaitOffer.speciality  === '121' || awaitOffer.speciality  === '131' || awaitOffer.speciality  === '141' || awaitOffer.speciality  === '135' || awaitOffer.speciality  === '136' || awaitOffer.speciality  === '137' || awaitOffer.speciality  === '311'
          || awaitOffer.speciality  === 'Prasa' || awaitOffer.speciality  === 'Laser' || awaitOffer.speciality  === 'Frezarka' || awaitOffer.speciality  === 'Tokarka')) {
            setShowPopUpModal(true)
            setModalVersion(2)
            window.scrollTo(0,0)
            setModalInfo({title:'Uzupełnij Dane:', message:'- Wybierz Kraj którego dotyczy oferta'})
          } else {
            saveDataToCloud({
              ...awaitOffer,
              visibility: true,
              addDate: Date.now(),
              paid: awaitOffer.option === 'premium' && LoggedUser.PremiumOffers > 0 ? true : '',
              packageActivation: awaitOffer.option === 'premium' && LoggedUser.PremiumOffers > 0 ? Date.now() : '',
            })

            //aktywacja podstawowej ze zmianą na płatną nie dodaje statusu paid === true gdy user ma pakiet

            window.scrollTo(0,0)
            setRefresh(refresh + 21)
            if (history.location.pathname === '/EdycjaOgloszenia') {
              history.push({
                pathname: '/userPanel',
                state: {
                    from: '/EdycjaOgloszenia'
                }
              })
            } else {
              history.push({
                pathname: '/userPanel',
                state: {
                    from: '/DodajOgłoszenie'
                }
              })
            }
          }
          };

          const cancelEdit = () => {
            setAwaitOffer(null)
            setDetailedOffer(null)
            setCountry('')
            setCountryFilterCode('')
            setCountryFilter('')
            setRefresh(refresh + 0.5)
            history.push({
              pathname: '/userPanel'
            })
          };

          const saveOffersStats = async (data) => {
            const date = new Date();
            let year = date.getFullYear();
            let month = date.getMonth() + 1;
            try {
              await get(child(dbRef, `stats/offersMonthlyCounter/${year}/${month}/${data.role}`))
              .then((statValue) => {
                if ( statValue.val() === null) {
                  set(AddOfferRef(db, `stats/offersMonthlyCounter/${year}/${month}/${data.role}/offers`), 1)
                } else if (statValue.val() && statValue.val().offers) {
                  set(AddOfferRef(db, `stats/offersMonthlyCounter/${year}/${month}/${data.role}/offers`), increment(1))
                }
              })
            } catch (error) {
              console.log(error)
              set(AddOfferRef(db, `errors/${year}/${month}/${day}/saveOffersStats`), error.message)
            }
          };

          const updateUserPremiumOffersNum = async () => {

            try {
              await get(child(dbRef, `users/${LoggedUser.keyName}/PremiumOffers`))
              .then((statValue) => {
                if (statValue.val() > 0 && (ChoosedOption === 'premium' || awaitOffer.option === 'premium')) {
                  set(AddOfferRef(db, `users/${LoggedUser.keyName}/PremiumOffers`), increment(-1))
                  set(AddOfferRef(db, `users/${LoggedUser.keyName}/PremiumOffersUse/${year}/${month}/${awaitOffer.id}`), {date:Date.now(), title: awaitOffer.title, country: awaitOffer.country}) 
                } else if (statValue.val() === 0 || statValue.val() < 0) {
                   return
                }
              })
            } catch (error) {
              console.log(error)
              set(AddOfferRef(db, `errors/${year}/${month}/${day}/updateUserPremiumOffersNum`), error.message)
            }
          };

          const payAndSave = () => { // opcja dla pojedyńczej płatnej oferty - premium
            saveDataToCloud({...awaitOffer, visibility: false, paid: false})
            setRefresh(refresh + 1)
          };

          const saveDataToCloud = (data) => {

            try {
              set(AddOfferRef(db, `offers/${data.id}`), data)
              .then(() => {
                if (data.paid !== false) {
                    setCityFilter('')
                    setShowPopUpModal(true)
                    setModalVersion(12)
                    setModalInfo(history.location.state.from === '/EdycjaOgloszenia' ?
                    {title:'Zmiany zostały zapisane'} : {title:'Dziękujemy za dodanie oferty'})
                    setDetailedOffer('')
                    setTitle('')
                    setExperience('Wybierz')
                    setSelectedRole('Wybierz')
                    setSelectedSpeciality('Wybierz')
                    setCountry('Wybierz')
                    setDescription('')
                    setMinSalary('')
                    setMaxSalary('')
                    setChoosedOption('')
                    setAwaitOffer('')
                    setStepper(0)
                    saveOffersStats(data)
                    updateUserPremiumOffersNum()
                    setApplyWithSJProfile('Tak')
                }
              })
            } catch (error) {
              setShowPopUpModal(true)
              setModalVersion(12)
              setModalInfo({title:'Ups coś poszło nie tak... sprawdzamy co się stało daj nam chwilę'})
              set(AddOfferRef(db, `errors/${year}/${month}/${day}/saveDataToCloud`), error.message)
            }
          }; 


          const showButtonsAccordingToPath = () => {
            if (history.location.pathname === '/DodajOgłoszenie') {
                 return(
                    <div>
                    { StatuteAcceptation ?
                        <div>
                        {ChoosedOption ==='basic' || LoggedUser.PremiumOffers > 0 ?
                          <div id='PayButton' onClick={addFreeOffer}>
                            <p>Dodaj Ofertę</p>
                          </div>
                     :
                        <StripeButton
                          saveOffer={payAndSave}
                       //   prodPrice={prodPriceTest} // testowa cena produkcyjna
                          prodPrice={ChoosedOption === 'basic+' ? prodPriceBasicPlus : newPremiumPrice} //ceny produkcyjne
                          offerId={awaitOffer.id}
                          setModalInfo={setModalInfo}
                          User={LoggedUser.name}
                          UserMail={LoggedUser.email}
                          set={set}
                          AddOfferRef={AddOfferRef}
                          db={db}
                        />
                       }
                      </div> :
                         <div id='FakePayButton'> 
                           <p>Aby dodać ofertę zaakceptuj regulamin</p>
                         </div>
                    }
                    </div>
                 )
            } else if (history.location.pathname === '/EdycjaOgloszenia') {  // Button dla edycji ogłoszenia niezależnie od opcji
                return (
                  <div className='EditOfferBtnsContainer'>
                     <div id='PayButton' onClick={addFreeOffer}>
                        <p>Zapisz</p>
                      </div>
                      <div id='CancelButton' onClick={cancelEdit}>
                        <p>Anuluj</p>
                      </div>
                  </div>
                )
            } else if (history.location.pathname === '/userPanel') { // aktywacja ogłoszenia z poziomu panelu użytkownika
               if ( awaitOffer.option === 'basic' || ( awaitOffer.option === 'premium' && LoggedUser.PremiumOffers > 0 )) {
                return (
                  <div id='PayButton' onClick={addFreeOffer}>
                    <p>Aktywuj</p>
                  </div>
                )
               } else if ( awaitOffer.option === 'premium' && LoggedUser.PremiumOffers === 0 ) {
                return (
                  <StripeButton
                    saveOffer={payAndSave}
                 // prodPrice={prodPriceTest} // testowa cena produkcyjna
                    prodPrice={newPremiumPrice}
                    offerId={awaitOffer.id}
                    setModalInfo={setModalInfo}
                    User={LoggedUser.name}
                    UserMail={LoggedUser.email}
                    set={set}
                    AddOfferRef={AddOfferRef}
                    db={db}
                />
                )
               }
            }
          };

            return (
                <div>
                   {showButtonsAccordingToPath()}
                </div>
              )
           }

       export default ShowAddButtons;
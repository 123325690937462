import React, {useState, useEffect} from 'react';
import './LoginPage.css';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { motion } from "framer-motion";
import LoadingAnimation from '../LoadingAnimation/LoadingAnimation';
import { FcAcceptDatabase } from 'react-icons/fc';
import { AiFillEye } from 'react-icons/ai';
import { AiFillEyeInvisible } from 'react-icons/ai';
import { MdBlock } from 'react-icons/md';
import PopUpModal from '../PopUpModal/PopUpModal';
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword, createUserWithEmailAndPassword, sendEmailVerification, sendPasswordResetEmail } from "firebase/auth";
import { useAtom } from 'jotai';
import { useLoggedUser, useShowPopUpModal } from '../../data/GlobalStates';

const Loginpage = ({authedUser}) => {

    //stany lokalne:
    const [screenType, setScreenType ] = useState(0);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");
    const [errorInfo, setErrorInfo] = useState("");
    const [passWarning, setPassWarning] = useState('');
    const [blockRegister, setBlockRegister] = useState(false);
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [isPasswVisible, setIsPasswVisible] = useState(false);
    //stany globalne:
    const [ LoggedUser, setLoggedUser ] = useAtom(useLoggedUser);
    const [ ShowPopUpModal, setShowPopUpModal] = useAtom(useShowPopUpModal);

    const auth = getAuth();
    const history = useHistory();

    const logUser = (email, password) => {
        if (email.length === 0) {
          setErrorInfo('podaj e-mail')
          setTimeout(() => {
            setErrorInfo('')
          },2200)
        } else if ( email.length > 0) {
          setBlockRegister(true)
          signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
              history.push('/userPanel')
            })
            .catch((error) => {
             // const errorCode = error.code;
              const errorMessage = error.message;
              displayErrors(errorMessage)
              setTimeout(() => {
                setErrorInfo('')
              },3000)
              setBlockRegister(false)
            });
        }
      };

      const resetPassw = () => {
        if (!blockRegister) {
            setBlockRegister(true)
            sendPasswordResetEmail(auth, email).then(() => {
              setTimeout(() => {
                setErrorInfo('e-mail wysłany')
                setBlockRegister(false)
               },6000)
            }).catch((error) => {
              setTimeout(() => {
                displayErrors(error.message)
                setBlockRegister(false)
               },6000)
          })
        }
      };

      const switchPasswordVisibility = () => {
        setIsPasswVisible(!isPasswVisible)
      };

      const displayErrors = (errorMessage) => {
        if (errorMessage.includes('missing-email')) {
          setErrorInfo('błędny e-mail')
        } else if (errorMessage.includes('weak-password')) {
          setErrorInfo('hasło zbyt krótkie')
        } else if (errorMessage.includes('email-already-in-use')) {
         setErrorInfo('podany e-mail jest już zarejestrowany')
        } else if (errorMessage.includes('wrong-password')) {
         setErrorInfo('błędne hasło')
        } else if (errorMessage.includes('user-not-found')) {
         setErrorInfo('e-mail niezarejestrowany')
        } else if (errorMessage.includes('invalid-email')) {
         setErrorInfo('błędny e-mail')
        }
     };

     const checkIsPasswordVisible = () => {
       if (isPasswVisible) {
        return 'text'
       } else if (!isPasswVisible) {
        return 'password'
       }
     };

    const backToMainPage = () => {
        history.push('/')
     };

     const setEmailVal = (e) => {
        setEmail(e.target.value)
     };

     const setPasswordVal = (e) => {
        setPassword(e.target.value)
    };

    const setPasswordVal2 = (e) => {
        setPassword2(e.target.value)
    };

    const changeScreenVer = (val) => {
        setScreenType(val)
        setErrorInfo('')
    };

    const acceptTerms = () => {
      setTermsAccepted(!termsAccepted)
    };

    const registerUser = () => {
        if (!passWarning && !blockRegister && termsAccepted) {
            createUserWithEmailAndPassword(auth, email, password)
            .then(() => { // rejestracja
              history.push('/userPanel')
            })
            .catch((error) => {
             // const errorCode = error.code;
              const errorMessage = error.message;
              displayErrors(errorMessage)
              setBlockRegister(false)
              // ..
            }).then(
              onAuthStateChanged(auth, (user) => {
                if (user) {
                  sendEmailVerification(user)
                }
              })
            );
            setBlockRegister(true)
        }
      };

      const displayPasswordVisibilitySwitch = () => { 
           if (isPasswVisible && !blockRegister && !errorInfo) {
            return (
               <AiFillEyeInvisible className='passwordVisibilitySwitchIcon' onClick={switchPasswordVisibility}/>
            )
           } else if (!isPasswVisible && !blockRegister && !errorInfo) {
            return (
               <AiFillEye className='passwordVisibilitySwitchIcon' onClick={switchPasswordVisibility}/>
            )
           } else if (blockRegister && (isPasswVisible || !isPasswVisible)) {
            return null
           }
      };

    useEffect(() => {
        if (password.length > 1 && password !== password2) {
          setPassWarning('hasła nie są takie same')
        } else if (password === password2) {
          setPassWarning('')
        }
       }, [password, password2])

       useEffect(() => {
         if (authedUser.currentUser && LoggedUser) {
          history.push('/userPanel')
          return
         } else return
       }, [authedUser, LoggedUser])


    const showLoginView = () => {

        const variants = {
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }

       return(
        <motion.div id='LoginScreen' transition={{ duration: 0.8 }} variants={variants} initial="hidden" animate="visible" onKeyPress={(e) => e.key === 'Enter' && logUser(email, password)}>
        <h1 id='LoginLogo' onClick={backToMainPage}>SteelJobs.pl</h1>
          <p className='SignPageSubTitle'>Logowanie</p>
        { errorInfo ? <h3 id='ErrorMessages'>{errorInfo}</h3> : ''}

        { blockRegister ? <LoadingAnimation classVariant={'loginPage'}/> : ''}

               <input className={blockRegister? 'BlockedInputs' : 'LoginInputs'} placeholder='e-mail' onChange={setEmailVal}/>
               <div className='PasswordAndVisibilityIconDiv'>
                 <input className={blockRegister? 'BlockedInputs' : 'LoginInputs'} placeholder='hasło' type={checkIsPasswordVisible()} onChange={setPasswordVal}/>
                 { displayPasswordVisibilitySwitch() }
               </div>   
                   <div id='LoginBtn' onClick={() => {logUser(email, password)}}>
                      <p>Zaloguj</p>
                   </div>
           <div id='LoginLinks'>
              <div className='Link' onClick={() => {changeScreenVer(1)}}>
                  <p>Rejestracja</p>
              </div>
              <div className='Link' onClick={() => {changeScreenVer(2)}}>
                  <p>Resetuj Hasło</p>
              </div>
           </div>
             <p className='registerOwnerInfo'>Administratorem danych jest firma: DK SoftTest Gdańsk ul.Wawelska 10b/11. W razie pytań skontaktuj się z nami: steeljobs@steeljobs.pl</p>
             <p className='registerOwnerInfo'>Gdańsk 2021</p>
        </motion.div>
       )
      };

      const showRegisterView = () => {

        return(
         <div id='RegisterScreen'> 
            <h1 id='LoginLogo' onClick={backToMainPage}>SteelJobs.pl</h1>
               <p className='SignPageSubTitle'>Rejestracja</p>
               { errorInfo ? <h3 id='ErrorMessages'>{errorInfo}</h3> : ''}

               { blockRegister ? <LoadingAnimation classVariant={'loginPage'}/> : ''}

                    <input className={blockRegister? 'BlockedInputs' : 'LoginInputs'} placeholder='e-mail' onChange={setEmailVal}></input>
                      <input className={blockRegister? 'BlockedInputs' : 'LoginInputs'} placeholder='hasło' type='password' onChange={setPasswordVal}></input>
                        <input className={blockRegister? 'BlockedInputs' : 'LoginInputs'} placeholder='powtórz hasło' type='password' onChange={setPasswordVal2}></input>
                           <h2 id='passWarning'>{passWarning}</h2>
                              <div id='termsAndConditions'>
                                 <input type='checkbox' onChange={acceptTerms}/>
                                   <Link to='/Polityka' className='LoginTermsLink'>Akceptuje <strong className='PolicyLinkText'>Regulamin</strong></Link> 
                                     {termsAccepted ? <FcAcceptDatabase /> : <MdBlock />}
                              </div>

                     <div id='LoginBtn' onClick={() => {registerUser()}}>
                       <p>Rejestruj</p>
                     </div>
                         <div id='LoginLinks'>
                             <div className='Link' onClick={() => {changeScreenVer(0)}}>
                               <p>Logowanie</p>
                             </div>
                             <div className='Link' onClick={() => {changeScreenVer(2)}}>
                               <p>Resetuj Hasło</p>
                             </div>
                        </div>
                        <p className='registerOwnerInfo'>Administratorem danych jest firma: DK SoftTest Gdańsk ul.Wawelska 10b/11. W razie pytań skontaktuj się z nami: info@steeljobs.pl</p>
                        <p className='registerOwnerInfo'>Gdańsk 2021</p>
         </div>
        )
       };

       const showPassResetView = () => {
        return(
         <div id='PasswResetMainContainer'>
          <h1 id='LoginLogo' onClick={backToMainPage}>SteelJobs.pl</h1>
            { errorInfo ? <h3 id='ErrorMessages'>{errorInfo}</h3> : ''}
              { blockRegister ? <LoadingAnimation classVariant={'loginPage'}/> : <input className='LoginInputs' placeholder='e-mail' onChange={setEmailVal}></input>}
                { !blockRegister ?
                  <div id='LoginBtn' onClick={() => {resetPassw()}}>
                    <p>Resetuj hasło</p>
                  </div >
                  : ''
                }
                  <div id='Links'>
                    <div className='Link' onClick={() => {changeScreenVer(0)}}>
                      <p>Logowanie</p>
                    </div>
                  </div>
            </div>
        )
       };

    return (
        <div id='LoginPageMainDiv'>
             { ShowPopUpModal ? <PopUpModal/> : ''}
               <div id={screenType === 0 ? 'Form' : 'FormReg'}>
                { screenType === 0 ? showLoginView() : ''}
                { screenType === 1 ? showRegisterView() : ''}
                { screenType === 2 ? showPassResetView() : ''}
             </div>
        </div>
    )
};

export default Loginpage;

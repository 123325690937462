import React from 'react';
import './AddOfferInfo.css';
import '../SingleOffer/SingleOffer.css'
import { useHistory } from 'react-router-dom';
import OfferTypes from '../../data/OfferTypes';
import { BiWrench } from 'react-icons/bi';
import { GiAncientScrew } from 'react-icons/gi';
import { GiStoneWheel } from 'react-icons/gi';
import { GiBlacksmith } from 'react-icons/gi';
import { useAtom } from 'jotai';
import { useShowPopUpModal, useModalVersion, useModalInfo } from '../../data/GlobalStates';


const AddOfferInfo = () => {

  const history = useHistory();
  //stany globalne:
  const [ ShowPopUpModal, setShowPopUpModal] = useAtom(useShowPopUpModal);
  const [ ModalVersion, setModalVersion] = useAtom(useModalVersion);
  const [ ModalInfo, setModalInfo] = useAtom(useModalInfo);

  const backToMain = () => {
    history.push('/')
  };

  const moveToRegister = () => {
      history.push('/login')
      setShowPopUpModal(true)
      setModalVersion(2)
      setModalInfo({title:'Nie jesteś zalogowany', message:'Aby dodać ofertę zaloguj się lub załóż konto'})
  };

    return (
        <div id='AddOfferInfoMainDiv'>
          <h1 id='Logo' onClick={backToMain}>SteelJobs.pl</h1>
            <h2 className='AddOfferInfoText'>Dzięki nam dotrzesz do pracowników branży metalurgicznej</h2>
               <h2 className='AddOfferInfoText'>Dostępne rodzaje pojedyńczych ogłoszeń w dziale ofert pracy:</h2>
               <div id='Samples'>
                 <div className='SampleContainer' onClick={moveToRegister}>
                   <div className='AddOfferInfoHeaderContainer'>
                     <h2 className='DisplayTypeHeader'>Ogłoszenie Podstawowe</h2>
                   </div>
                   <div className='OfferTypeInfo'>
                    <p>- <strong id='GratisInfo'> Brak opłat</strong></p>
                    <p>- Czas wyświetlania <strong>{OfferTypes[0].displayTime}</strong> dni</p>
                   </div>
                   <div className='IconsContainer'>
                     <BiWrench className='AddOfferIcons'/>
                       <GiAncientScrew className='AddOfferIcons' id='animatedScrew'/>
                       <GiStoneWheel className='AddOfferIcons'/>
                     <GiBlacksmith className='AddOfferIcons'/>
                   </div>
                 </div>
            <div className='SampleContainer' onClick={moveToRegister}>
               <div className='AddOfferInfoHeaderContainer'>
                 <h2 className='DisplayTypeHeader'>Ogłoszenie Premium</h2>
               </div>
               <div className='OfferTypeInfo'>
                  <p><strong>{OfferTypes[2].price} zł</strong> + 23% Vat</p>
                  <p>- Czas wyświetlania <strong>{OfferTypes[2].displayTime}</strong> dni </p>
                  <p>- <strong>Wyróżnienie na liście ogłoszeń</strong></p>
                  <p>- <strong>Wyróżnienie na mapie ofert</strong></p>
                  <p>- wyświetlanie przed ofertami podstawowymi</p>
               </div>
               <div className='IconsContainer'>
                   <BiWrench className='AddOfferIcons'/>
                   <GiAncientScrew className='AddOfferIcons'/>
                   <GiStoneWheel className='AddOfferIcons' id='animatedWheel'/>
                   <GiBlacksmith className='AddOfferIcons'/>
               </div>
          </div>
         </div>
         <div id='Samples'>
           <h3>Pakiety ogłoszeń:</h3>
           <p className='AddOfferInfoText'>Oferty do wykorzystania w ramach pakietu dodawane są do konta automatycznie po zaksięgowaniu płatności.</p>
           <p className='AddOfferInfoText'>Czas na ich wykorzystanie jest nieograniczony.</p>
           <div className='SampleContainer' onClick={moveToRegister}>
             <div className='AddOfferInfoHeaderContainer'>
                <h2 className='DisplayTypeHeader'>Pakiet 3 ofert premium</h2>
             </div>
               <div className='OfferTypeInfo'>
                  <p>- <strong>{OfferTypes[3].price}</strong> PLN + 23% Vat</p>
                  <p>- cena jednostkowa <strong>{OfferTypes[3].price/3}</strong> PLN + 23% Vat</p>
               </div>
               <div className='IconsContainer'>
                   <BiWrench className='AddOfferIcons'/>
                   <GiAncientScrew className='AddOfferIcons'/>
                   <GiStoneWheel className='AddOfferIcons'/>
                   <GiBlacksmith className='AddOfferIcons'/>
               </div>
          </div>
          <div className='SampleContainer' onClick={moveToRegister}>
             <div className='AddOfferInfoHeaderContainer'>
                <h2 className='DisplayTypeHeader'>Pakiet 5 ofert premium</h2>
             </div>
               <div className='OfferTypeInfo'>
                  <p>- <strong>{OfferTypes[4].price}</strong> PLN + 23% Vat</p>
                  <p>- cena jednostkowa <strong>{OfferTypes[4].price/5}</strong> PLN + 23% Vat</p>
               </div>
               <div className='IconsContainer'>
                   <BiWrench className='AddOfferIcons'/>
                   <GiAncientScrew className='AddOfferIcons'/>
                   <GiStoneWheel className='AddOfferIcons'/>
                   <GiBlacksmith className='AddOfferIcons'/>
               </div>
          </div>
          <div className='SampleContainer' onClick={moveToRegister}>
             <div className='AddOfferInfoHeaderContainer'>
                <h2 className='DisplayTypeHeader'>Pakiet 10 ofert premium</h2>
             </div>
               <div className='OfferTypeInfo'>
                  <p>- <strong>{OfferTypes[5].price}</strong> PLN + 23% Vat</p>
                  <p>- cena jednostkowa <strong>{OfferTypes[5].price/10}</strong> PLN + 23% Vat</p>
               </div>
               <div className='IconsContainer'>
                   <BiWrench className='AddOfferIcons'/>
                   <GiAncientScrew className='AddOfferIcons'/>
                   <GiStoneWheel className='AddOfferIcons'/>
                   <GiBlacksmith className='AddOfferIcons'/>
               </div>
          </div>
         </div>
         <div id='RegisterInfo'>
            <h1 className='AddOfferInfoText'>Akceptowane formy płatności:</h1> 
             <p>- BLIK</p>
             <p>- Karta Płatnicza</p>
             <p>- Przelewy 24</p>
             <p>- Link</p>
             <p>- Google Pay</p>
            <h1 className='AddOfferInfoText'>Chcesz dodać ogłoszenie ? </h1>
            <button id='RegisterBtn'  onClick={() => {history.push('/login')}}>Rejestracja</button>
          </div>
         <div id='AddOfferInfoContactDiv'>
               <h1 className='AddOfferInfoText'>Masz pytania ?</h1>
               <h2 className='AddOfferInfoText'>Skontaktuj się z nami:</h2>
               <div id='ContactDoubleDiv'>
                   <div className='InfoBox'>
                    <p>Tel: 508 568 824</p>
                   </div>
                   <div className='InfoBox'>
                    <p>mail: steeljobs@steeljobs.pl</p>
                   </div>
               </div>
           </div>
        </div>
    )
}

export default AddOfferInfo;
